export const useReservationFilters = () => {
  const params = useUrlSearchParams('history', {
    removeFalsyValues: true,
  });

  const formData = reactive({
    page: 1,
    agencyId: [],
    pageSize: 20,
    dateRange: {
      start: null,
      end: null,
    },
  });

  interface IPayload {
    page: number;
    pageSize: number;
    'filter[requested_at_between]': string;
    'filter[agency_id]': string;
  }

  const formattedDateRange = computed(() => {
    if (!formData.dateRange.start || !formData.dateRange.end) return;
    return {
      start: formData.dateRange.start.toString(),
      end: formData.dateRange.end.toString(),
    };
  });

  const paramsFilters = computed(() => {
    let payload: IPayload = {
      page: formData.page,
      pageSize: formData.pageSize,
      'filter[requested_at_between]': '',
      'filter[agency_id]': '',
    };

    if (
      formattedDateRange.value &&
      formattedDateRange.value.start &&
      formattedDateRange.value.end
    ) {
      payload = {
        ...payload,
        'filter[requested_at_between]': `${formattedDateRange.value.start},${formattedDateRange.value.end}`,
      };
    }

    if (formData.agencyId.length > 0) {
      payload = {
        ...payload,
        'filter[agency_id]': formData.agencyId.join(','),
      };
    }

    return payload;
  });

  const setUrlParams = async () => {
    const formDataParams = unref(paramsFilters);
    Object.keys(formDataParams).forEach((key) => {
      const keyParam = key;
      params[keyParam] = formDataParams[keyParam];
    });
  };

  return {
    formData,
    paramsFilters,
    setUrlParams,
  };
};
