<script setup lang="ts">
  import type { Reservation } from '~/types/reservations';
  import { useReservationFilters } from '~/composables/useReservationFilters';
  import type { IPagination } from '~/types';

  import { reservationColumns } from '~/config/reservationColumns';

  const sanctumFetch = useSanctumClient();
  const { formData, paramsFilters, setUrlParams } = useReservationFilters();

  onMounted(async () => await setUrlParams());

  const sort = ref({
    column: 'id',
    direction: 'desc',
  });

  const [
    { data: agencies, pending: agenciesPending },
    { data: items, pending: reservationsPending, refresh: refreshReservations },
  ] = await Promise.all([
    await useLazyAsyncData(
      'reservationAgencyItems',
      async () =>
        sanctumFetch('/api/v1/agencies', {
          query: {
            page: 1,
            pageSize: 100,
          },
        }),
      {
        immediate: true,
      }
    ),
    await useLazyAsyncData(
      'reservations/items',
      async () =>
        await sanctumFetch('/api/v1/reservations', {
          params: {
            ...unref(paramsFilters),
            sort: sort.value.direction === 'asc' ? sort.value.column : `-${sort.value.column}`,
          },
        }),
      {
        immediate: true,
        default: () => [],
        watch: [sort],
      }
    ),
  ]);

  const agencyOptions = computed(() => {
    return agencies.value?.data.map((item) => ({
      value: item.id,
      label: item.name.toUpperCase(),
    }));
  });

  const reservations = computed<Reservation[]>(() => {
    const inputs = items.value?.data || [];
    return inputs.map((item) => {
      return {
        ...item,
        class: item.isCancelled ? 'bg-red-200 hover:bg-red-200 text-gray-700' : '',
      };
    });
  });

  const hasPagination = computed(() => reservations.value?.length > 0);

  const pagination = computed<IPagination>(() => items.value?.meta || {});

  const principalGuestName = (item: Reservation) => {
    if (!item.guests) return '';

    return item.guests
      .filter((guest) => guest.isPrincipal)
      .map((guest) => guest.name)
      .join(', ');
  };

  watch(
    () => formData.page,
    () => onSubmitted()
  );

  const canSubmit = computed(() => {
    return formData.agencyId.length === 0 && (!formData.dateRange.start || !formData.dateRange.end);
  });

  const clearFilters = async () => {
    formData.agencyId = [];
    formData.dateRange = {
      start: null,
      end: null,
    };
    await onSubmitted();
  };

  const onSubmitted = useDebounceFn(async () => {
    await setUrlParams();
    await refreshReservations();
  }, 200);
</script>

<template>
  <div>
    <div class="flex items-center">
      <ReservationActions />
    </div>
    <div class="mt-2 ring-offset-background">
      <Card class="rounded">
        <CardHeader>
          <CardDescription>
            <div class="flex items-center gap-2.5">
              <USelectMenu
                v-model="formData.agencyId"
                name="agency_id[]"
                :options="agencyOptions"
                :loading="agenciesPending"
                placeholder="« Filter by Agency »"
                :multiple="true"
                :searchable="true"
                :popper="{ offsetDistance: 2 }"
                class="min-w-80"
                select-class="h-9"
                value-attribute="value"
              />

              <XDateRangePicker
                v-model="formData.dateRange"
                class="ml-1 py-0"
                :disabled="reservationsPending"
              />

              <UButton
                icon="i-heroicons-outline-search"
                loading-icon="i-lucide-loader"
                :loading="reservationsPending"
                :disabled="reservationsPending || canSubmit"
                size="md"
                :ui="{ base: 'h-9' }"
                @click="onSubmitted"
                >Search</UButton
              >
              <UButton
                v-if="!canSubmit"
                icon="i-heroicons-outline-filter"
                variant="outline"
                loading-icon="i-lucide-loader"
                size="md"
                :ui="{ base: 'h-9' }"
                @click="clearFilters"
                >Reset</UButton
              >
            </div>
          </CardDescription>
        </CardHeader>
        <CardContent>
          <UTable
            v-model:sort="sort"
            :rows="reservations"
            :columns="reservationColumns"
            :loading="reservationsPending"
            :loading-state="{
              icon: 'i-lucide-loader',
              label: 'Loading...',
            }"
            :ui="{
              base: 'w-full min-h-[600px] table-auto',
              th: {
                base: 'text-left rtl:text-right whitespace-nowrap',
                padding: 'px-2 py-2.5',
              },
              td: {
                padding: 'px-2 py-2.5',
                size: 'text-xs',
              },
              default: { shortButton: { color: 'primary' } },
            }"
          >
            <template #id-data="{ row }">
              <div class="flex items-center gap-1.5">
                <span>{{ row.id }}</span>
                <UTooltip :text="row.flagArrivalTitle" :popper="{ placement: 'bottom' }">
                  <UIcon
                    name="i-fa6-solid-plane-arrival"
                    :class="`text-${row.flagArrival}-500 h-4 w-4`"
                  />
                </UTooltip>
              </div>
            </template>
            <template #actions-data="{ row }">
              <ReservationRowActions :item="row" @refresh="refreshReservations" />
            </template>
            <template #completedAt-data="{ row }">
              <span class="uppercase">{{
                row.completedAt ? useDateFormat(row.completedAt, 'DD/MMM/YYYY') : null
              }}</span>
            </template>
            <template #requestedAt-data="{ row }">
              <span class="uppercase">{{
                row.requestedAt ? useDateFormat(row.requestedAt, 'DD/MMM/YYYY') : null
              }}</span>
            </template>
            <template #guests-data="{ row }">
              <span class="font-medium uppercase">{{ principalGuestName(row) }}</span>
            </template>
            <template #agency-data="{ row }">
              <span class="hidden uppercase md:table-cell">{{ row.agency?.name }}</span>
            </template>
            <template #confirmationNumber-data="{ row }">
              <span class="text-center">{{ row.confirmationNumber }}</span>
            </template>

            <template #rf-data="{ row }">
              <span>{{ row.rf ? `$${row.rf}` : null }}</span>
            </template>

            <template #paidFormatted-data="{ row }">
              <Badge v-if="row.paidFormatted" class="rounded-lg px-1" variant="secondary">
                {{ row.paidFormatted }}
              </Badge>
            </template>
          </UTable>
        </CardContent>
        <CardFooter>
          <CustomPagination v-if="hasPagination" v-model="formData.page" :pagination="pagination" />
        </CardFooter>
      </Card>
    </div>
  </div>
</template>
