<script setup lang="ts">
  const isOpenModalToExport = ref(false);
</script>

<template>
  <div class="ml-auto flex items-center gap-2">
    <DropdownMenu>
      <DropdownMenuTrigger as-child>
        <Button variant="outline" size="sm" class="h-7 gap-1">
          <Icon name="lucide:list-filter" class="h-3.5 w-3.5" />
          <span class="sr-only sm:not-sr-only sm:whitespace-nowrap"> Filter </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Filter by</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem checked> Active</DropdownMenuItem>
        <DropdownMenuItem> Canceled</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
    <UButton
      size="xs"
      variant="soft"
      class="h-7 gap-1"
      :ui="{ base: 'border-dashed' }"
      @click="isOpenModalToExport = true"
    >
      <Icon name="lucide:file" class="h-3.5 w-3.5" />
      <span class="sr-only sm:not-sr-only sm:whitespace-nowrap"> Export </span>
    </UButton>
    <UButton
      size="xs"
      class="h-7 gap-1"
      icon="lucide:circle-plus"
      variant="soft"
      @click="navigateTo('/reservations/create')"
    >
      <span class="sr-only sm:not-sr-only sm:whitespace-nowrap"> Add </span>
    </UButton>

    <LazyReservationModalExport v-model="isOpenModalToExport" />
  </div>
</template>

<style scoped></style>
