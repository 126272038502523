<script setup lang="ts">
  import type { DateRange } from 'radix-vue';
  import type { HTMLAttributes } from 'vue';
  import { Button } from '@/components/ui/button';
  import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
  import { RangeCalendar } from '@/components/ui/range-calendar';
  import { cn } from '@/utils';
  import { DateFormatter, getLocalTimeZone } from '@internationalized/date';
  import { CalendarIcon } from '@radix-icons/vue';

  const df = new DateFormatter('en-US', {
    dateStyle: 'medium',
  });

  const props = defineProps<{
    modelValue?: DateRange;
    class?: HTMLAttributes['class'];
  }>();

  const emit = defineEmits(['update:modelValue']);

  const onUpdateEnd = (value: DateRange) => emit('update:modelValue', value);

  const value = computed({
    get: () => props.modelValue ?? { start: null, end: null },
    set: (value: DateRange) => emit('update:modelValue', value),
  });
</script>

<template>
  <div :class="cn('grid gap-2')">
    <Popover>
      <PopoverTrigger as-child>
        <Button
          variant="outline"
          :class="
            cn(
              'w-[300px] justify-start text-left font-normal',
              !modelValue && 'text-muted-foreground',
              props.class
            )
          "
        >
          <CalendarIcon class="mr-2 h-4 w-4" />
          <template v-if="modelValue.start">
            <template v-if="modelValue.end">
              {{ df.format(modelValue.start.toDate(getLocalTimeZone())) }} -
              {{ df.format(modelValue.end.toDate(getLocalTimeZone())) }}
            </template>

            <template v-else>
              {{ df.format(modelValue.start.toDate(getLocalTimeZone())) }}
            </template>
          </template>
          <template v-else> Pick a range date </template>
        </Button>
      </PopoverTrigger>
      <PopoverContent class="w-auto p-0">
        <RangeCalendar
          v-model="value"
          initial-focus
          weekday-format="short"
          :number-of-months="2"
          @update:model-value="onUpdateEnd"
        />
      </PopoverContent>
    </Popover>
  </div>
</template>
