<script setup lang="ts">
  import { useReservationStore } from '~/store';
  import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from '~/components/ui/alert-dialog';

  const useReservation = useReservationStore();

  defineProps<{
    item: { id: number };
  }>();

  const emit = defineEmits<{
    refresh: () => void;
  }>();

  const goTo = (item: { id: number }) => {
    navigateTo(`/reservations/${item.id}/edit`);
  };

  const isDeleting = ref(false);
  const deleteReservationRow = async (item: { id: number }) => {
    isDeleting.value = true;
    await useReservation.removeReservationItem(item.id);
    useSuccessToast('Reservation Deleted', 'Reservation has been deleted successfully');
    emit('refresh');
    isDeleting.value = false;
  };
</script>

<template>
  <div class="flex content-center items-center justify-center gap-0">
    <UButton size="xs" variant="ghost" @click.stop="goTo(item)">
      <UIcon name="radix-icons:pencil-2" class="h-4 w-4" />
    </UButton>

    <AlertDialog>
      <AlertDialogTrigger as-child>
        <UButton size="xs" variant="ghost">
          <UIcon name="lucide:trash-2" class="h-4 w-4 text-red-500" />
        </UButton>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Reservation</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to delete this record?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            variant="danger"
            :disabled="isDeleting"
            class="bg-red-600 text-white hover:bg-red-800"
            @click.stop="deleteReservationRow(item)"
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  </div>
</template>

<style scoped></style>
