<script setup lang="ts">
  const user = useSanctumUser();

  window.Echo.channel(`App.Reservations.User.${user.value.id}`).listen(
    '.reservation.export.completed',
    (e) => {
      consoleInfo('ECHO', e);
    }
  );
</script>

<template>
  <DashboardPage class="max-w-full">
    <ReservationPage />
  </DashboardPage>
</template>

<style scoped></style>
