interface Column {
  key: string;
  label: string;
  class?: string;
  sortable?: boolean;
}

export const reservationColumns: Column[] = [
  { key: 'id', label: '#', sortable: true },
  { key: 'actions', label: 'Actions', class: 'w-[120px] text-center' },
  { key: 'requestedAt', label: 'Requested At', sortable: true },
  { key: 'completedAt', label: 'Completed At', class: 'truncate' },
  { key: 'checkin', label: 'checkin', sortable: true },
  { key: 'checkout', label: 'checkout' },
  { key: 'presDate', label: 'Pres Date', sortable: true },
  { key: 'guests', label: 'Full Name' },
  { key: 'agency', label: 'Agency' },
  { key: 'confirmationNumber', label: 'Confirmation Nº' },
  { key: 'additional', label: 'Additional' },
  { key: 'rf', label: 'Rf' },
  { key: 'paidFormatted', label: 'Paid' },
];
